import React from 'react'
import styled from '@emotion/styled'
import Fade from 'react-reveal/Fade'
import { Global, css } from '@emotion/core'

import Theme, { Mq } from '../themes/default'
import Layout from '../organisms/layout'
import { SubpageHero } from '../molecules/subpage-hero'
import SEO from '../organisms/seo'

const ContentStyles = css`
  h1 {
    font-size: 1.8rem;
    margin-bottom: 1rem;

    ${Mq.md} {
      font-size: 2.625rem;
    }
  }

  h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;

    ${Mq.md} {
      font-size: 2rem;
    }
  }

  h3 {
    font-size: 1.3rem;
    margin-bottom: 1rem;

    ${Mq.md} {
      font-size: 1.5rem;
    }
  }

  h4 {
    font-size: 1.1rem;
    margin-bottom: 1rem;

    ${Mq.md} {
      font-size: 1.3rem;
    }
  }

  p {
    font-size: 0.9375rem;
    line-height: 1.5625rem;
    margin-bottom: 1.5rem;

    ${Mq.md} {
      margin-bottom: 2rem;
      font-size: 1.0625rem;
      line-height: 1.6875rem;
    }
  }

  blockquote p {
    background: ${Theme.colors.lightBrown};
    font-family: ${Theme.fonts.primary};
    padding: 1rem;
    font-size: 2rem;
    line-height: 2.5rem;
    font-style: italic;
    margin-top: 1rem;
    margin-bottom: 2rem;

    ${Mq.md} {
      padding: 2rem;
    }
  }

  ul {
    list-style-type: circle;
  }

  ul,
  ol {
    margin-top: 1rem;
    margin-bottom: 2rem;
    padding-left: 2rem;
    font-size: 0.9375rem;
    line-height: 1.5625rem;

    ${Mq.md} {
      font-size: 1.0625rem;
      line-height: 1.6875rem;
    }

    li {
      margin-bottom: 1rem;
    }
  }

  a {
    color: ${Theme.colors.primary};
  }

  img {
    margin-bottom: 1.5rem;

    ${Mq.md} {
      margin-bottom: 2rem;
    }
  }
`

const AccessibilityPolicyContainer = styled.div`
  padding: 1rem 5%;

  ${Mq.md} {
    padding: 3rem 3% 7rem;
    margin: 0 5%;
  }
  ${Mq.xxl} {
    margin: 0 15%;
  }
`

const AccessibilityPolicyPage = () => (
  <Layout hideNavItems>
    <Global styles={ContentStyles} />
    <SEO description="BiasSync Digital Accessibility Policy" title="Digital Accessibility Policy" />
    <SubpageHero title="Digital Accessibility Policy" />
    <Fade bottom={true} cascade={true} distance={Theme.fadeDistance}>
      <AccessibilityPolicyContainer>
        <h3><b>General</b></h3>
        <p>At BiasSync, we’re committed to accessibility and inclusion of all kinds. Our policy is to ensure that everyone, including persons with disabilities, has full and equal access to our digital offerings.</p>
        <h3><b>Disclaimer</b></h3>
        <p>We are working to conform to the following accessibility standards:</p>
        <p><a href="https://www.w3.org/TR/WCAG/">Web Content Accessibility Guidelines (WCAG) 2.1 AA</a>, <a href="https://blogs.adobe.com/accessibility/2013/06/pdf-ua-2.html">PDF/Universal Accessibility (PDF/UA)</a>.</p>
        <p>BiasSync.com is compatible with most current browsers. You may experience difficulty if you are visiting on a browser more than five years old.</p>
        <p>BiasSync has appointed a Web Accessibility Task Force to help meet the objectives of our accessibility process. In addition to conducting accessibility audits, the Task Force oversees the implementation of BiasSync’s Digital Accessibility Policy.</p>
        <p>We are currently working to bring all <a href="https://biassync.com/">BiasSync.com</a> pages into compliance.</p>
        <h3><b>Here For You</b></h3>
        <p>If you are experiencing difficulty with any content on BiasSync.com or require assistance with any part of our site, we will be happy to assist. Please contact us during regular business hours as detailed below.</p>
        <h3><b>Contact Us</b></h3>
        <p>We welcome your feedback as you experience BiasSync.com. Please email us at <a href="mailto:inquiries@biassync.com">inquiries@biassync.com</a> or call (877) 921-3441 with any questions or comments you may have.</p>
      </AccessibilityPolicyContainer>
    </Fade>
  </Layout>
)

export default AccessibilityPolicyPage
